import axios from 'axios'
import apiDomain from '@/config/api.js'
import { wallet, wlcServer } from '@/config/domains.js'
import externalComponent from '@/utils/external-component.js'
import { EventBus } from '@/assets/static/event-bus.js'
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { StripeElementCard } from '@vue-stripe/vue-stripe';

import ModalCreateService from '@/components/modals/modalCreateService'
import Loader from '@/components/loader'
import Logo from '@/components/icons/Logo'

const Card = () => externalComponent('card');
const CardIcon = () => externalComponent('cardIcon');
const CardButton = () => externalComponent('cardButton');
const Toolbar = () => externalComponent('toolbar');
const Table = () => externalComponent('table');
const Pagination = () => externalComponent('pagination');
const FormInput = () => externalComponent('formInput');
const FormButton = () => externalComponent('formButton');
const Picker = () => externalComponent('picker');

export default {
  name: 'Payment',

  props: ['id'],

  components: {
    Card,
    CardIcon,
    CardButton,
    Toolbar,
    Table,
    Pagination,
    ModalCreateService,
    FormButton,
    FormInput,
    Picker,
    StripeCheckout,
    StripeElementCard,
    Logo,
    Loader
  },

  data() {
    // this.publishableKey = 'pk_test_wk6O7Cc5k3McBIG2Hut2irGs'; //process.env.STRIPE_PUBLISHABLE_KEY;
    this.publishableKey = 'pk_test_51Jm0YvHpxLAmUAghRV10BA89jRMYGYyQdjhDn2hPhWKpBA126GAAFnpE7iX4iP6OIoWiPSr1QI8UHglz9qC2Ke8900ZDTgzamC'; //process.env.STRIPE_PUBLISHABLE_KEY;
    return {
      checkout_valid: true,
      loaderVisible: false,
      booking_id: '',
      expiration: '',
      payment_done: false,
      payment: {
        card: "",
        name: "",
        expiration_date: "",
        cvv: ""
      },
      guests: [],
      covid_count: 0,
      guest: {},
      booking: {
        invoice: "",
        days: [],
        guests: "",
        price: ""
      },
      services: [],
      details: [],
      total: 0,
      subtotal: 0
    }
  },

  methods: {
    submitToken: function(e) {
      // this.loaderVisible = true;
      this.$refs.elementRef.submit();
    },

    tokenCreated: function(e) {
      this.loaderVisible = false;
      (() => {
        let cardToken = e.id,
          cardName = e.card.name;
        this.createCharge(cardToken, cardName);
      })()
    },

    createCharge: async function(cardToken, cardName) {
      this.loaderVisible = true;
      (async () => {
        if(this.booking.status !== 'sent') {
          this.$toast.error('This Booking is not active');
          this.checkout_valid = false;
          this.loaderVisible = false;
          return
        }

        let temp = {
          secret_key: 'B4FCF37F1F123B5D7C6F22764AE9A',
          data: {
            platform: 'stripe',
            // api_key:  'sk_test_4eC39HqLyjWDarjtT1zdp7dc' // STRIPE_API_KEY
            api_key:  'sk_test_51Jm0YvHpxLAmUAghdtdPJredyImx3JpZhWbVq5LrW0P0p2CpFThqdMBsy8d9AqNnoMWhSzj8AsFNsge7gz2te5QA00rT03EkaK' // STRIPE_API_KEY
          }
        };

        let walletToken = this.CryptoJS.AES.encrypt(JSON.stringify(temp.data), temp.secret_key).toString(),
          response,
          data = {
            customer: {
              name: cardName,
              source: cardToken
            },
            currency: 'USD',
            amount: parseInt(this.total*100)
          };
        
        temp = null;

        await axios
          .post(wallet, data, { headers: { 'token': walletToken } })
          .then(async res => {
            response = res;

            if (typeof response !== 'undefined' && response.status === 200) {
              response = res.data;

              let stripe_response = {
                stripe_id: response.id,
                paid: response.paid,
                receipt_url: response.receipt_url,
                amount: response.amount,
                amount_captured: response.amount_captured,
                status: response.status,
                link_id: this.id,
                guests: this.guests,
                details: this.details
              }
  
              await axios
                .post(apiDomain + "/booking/payment", stripe_response)
                .then(async res => {
                  // response = res.data;
                  this.$toast.success('Pay Success');
                  this.payment_done = true;
                  body__payment
                  try {
                    // let msg = 'Sneak Attack | Payment Received Order No: ' + this.booking.invoice + ' To: ' + this.guests[0] + ' Total: $' + stripe_response.amount/100 + ' USD';
                    // await axios
                    // .post(wlcServer + "/notification/sms", {message: msg, number: '+52 1 3311113154'}) // '+52 1 6241222186'}) 
                    // .then(res => {console.log('good  sms');})
                    // .catch(err => {console.log('err', err);});
                  } catch (error) {}//console.log(error);}
                })
                .catch(err => response = err);
            }
          })
          .catch(err => response = err);

        this.loaderVisible = false;
      })();
    },

    submit: function(e) {
      this.$refs.checkoutRef.redirectToCheckout();
    },

    getBooking: async function() {
      let response;

      (async () => {
        await axios
          .get(apiDomain + '/link/' + this.id)
          .then(res => response = res)
          .catch(err => response = err);

        if (typeof response !== 'undefined' && response.status === 200) {
          response = response.data;
          
          this.expiration = response.expiration;

          this.structallData({
            booking: response.booking[0],
            guest: response.guest[0],
            expiration: response.expiration
          });

          response = null;
        } else {
          this.$toast.error('Error al cargar datos');
          setTimeout(() => {
            // this.$router.push({ name: 'login' });
            this.checkout_valid = false;
          }, 1500);
        }
        
        
      })();
    },

    structallData: function(data) {
      (() => {
        let booking = data.booking,
          guest = data.guest;

        this.booking = {
          invoice: booking.invoice,
          days: booking.days,
          guests: booking.guests,
          tariff_price: booking.tariff_price,
          status: booking.status
        };

        this.subtotal = (booking.days).length * parseFloat(this.booking.tariff_price/100);
        this.total = this.total + this.subtotal;
        
        this.booking.tariff_price = (this.booking.tariff_price/100).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        this.guest = {
          name: guest.first_name + ' ' + guest.last_name
        };

        this.guests[0] = this.guest.name;
      })();
    },

    getServices: async function() {
      let response;

      await axios
        .get(apiDomain + '/service')
        .then(res => response = res)
        .catch(err => response = err);

      if (typeof response !== 'undefined' && response.status === 200) {
        response = response.data;

        response.forEach(element => {
          if(String(element[2].value).toLowerCase() == 'activa') {
            this.services.push({
              id: element[0].id,
              icon: (element[3].value).icon,
              title: element[0].value,
              description: (element[3].value).description,
              price: element[1].value,
              type: (element[3].value).type,
              multiple: 0
            });
          }
        });
      } else {

      }
    },

    addService: function(service) {
      if( this.details.find( detail => detail.id === service.id ) === undefined )
        this.details.push({
          id: service.id,
          title: service.title,
          price: service.price
        });
    },

    addServiceMultiple: function(service) {
      if( this.details.find(detail => detail.id === service.id ) === undefined )
        this.details.push({
          id: service.id,
          title: service.title,
          price: (service.price*service.multiple),
          multiple: service.multiple
        });
      else
        this.details.forEach(item => {
          if( item.id === service.id ) {
            item.price = (service.price*service.multiple);
            item.multiple = service.multiple;
          }
        });
    },

    deleteDetail: function(index) {
      this.details.splice(index, 1);
    },

    addGuest: function(count) {
      for (let index = 0; index < count; index++) {
        this.guests.push("");
      }
    },

    deleteGuest: function(index) {
      this.guests.splice(index, 1);
    },

    formatDate: function(event) {
      let inputChar = String.fromCharCode(event.keyCode),
        code = event.keyCode,
        allowedKeys = [8];

      if (allowedKeys.indexOf(code) !== -1) {
        return;
      }

      event.target.value = event.target.value.replace(
        /^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
      ).replace(
        /^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
      ).replace(
        /^([0-1])([3-9])$/g, '0$1/$2' // 13 > 01/3
      ).replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' // 141 > 01/41
      ).replace(
        /^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
      ).replace(
        /[^\d\/]|^[\/]*$/g, '' // To allow only digits and `/`
      ).replace(
        /\/\//g, '/' // Prevent entering more than 1 `/`
      );
    },

    extraServicesLoopCheckMultiples: function() {
      this.services.forEach(item => {
        if( item.type == "multiple" ) {
          if(item.multiple > 0)
            this.addServiceMultiple(item);

          if( item.multiple === 0 )
            this.details.forEach((item_detail, index) => {
              if( item_detail.id === item.id )
                this.deleteDetail(index);
            });
        }
      })
    }
  },

  mounted() {
    if( this.id === undefined ) {
      // this.$router.push({ name: 'login' });
      this.checkout_valid = false;
    } else {
      this.getBooking();
      this.getServices();
    }
  },

  watch: {
    details: {
      deep: true,
      handler(value) {
        let total = 0;

        this.details.forEach( element => {
          total = total + parseFloat(element.price)
        });

        this.total = this.subtotal + total;
      }
    },

    services: {
      deep: true,
      handler(value) {
        this.extraServicesLoopCheckMultiples();
      }
    }
  }
}
